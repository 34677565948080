<script>

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  computed: {
    rssFeed: function() {
      return process.env.VUE_APP_URL + 'tools/rss/podcast/' + this.data.podcast.link;
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <h6 class="card-title mb-0">
            About 
            <a :href="rssFeed" target="_blank" v-if="data.podcast.share === 2"><i class="ri-rss-line"></i></a>
          </h6>
        </div>
        <div class="flex-shrink-0">
          <ul class="list-inline d-flex align-items-center mb-0">
            <li class="list-inline-item" v-for="p in data.links" v-bind:key="p">
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 1 && p.url != ''">
                <i class="ri-facebook-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 2 && p.url != ''">
                <i class="ri-twitter-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 3 && p.url != ''">
                <i class="ri-instagram-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 4 && p.url != ''">
                <i class="ri-linkedin-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 5 && p.url != ''">
                <i class="ri-github-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 6 && p.url != ''">
                <i class="ri-telegram-line"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <p class="pre-line">{{ data.podcast.memo }}</p>
      <div class="d-flex flex-wrap gap-2 fs-16" v-if="data.tags != null && data.tags.length > 0">
        <div class="badge fw-medium badge-soft-light" v-for="p in data.tags" v-bind:key="p">
          <router-link :to="{ name: 'podcasts-tag', params: { value: p.title } }">
          #{{ p.title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
