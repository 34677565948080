<script>
import PodcastItem from "../podcasts/PodcastItem.vue";
import Authors from "./_Authors.vue";
import Table from "./_Table.vue";
import Actions from "./_Actions.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          authors: []
        }
      }
    }
  },
  components: {
    Authors,
    Table,
    PodcastItem,
    Actions,
  },
  computed: {
    isAuth: function() {
      return localStorage.getItem('user.id');
    }
  }
};
</script>

<template>
  <div class="team-list grid-view-filter row">
    
    <div class="col-12">
      <PodcastItem v-bind:data="data.podcast" v-bind:showUrl="true"></PodcastItem>
    </div>

    <div class="col-12" v-if="isAuth">
      <Actions v-bind:data="data.podcast"></Actions>
    </div>

    <div class="col-12">
      <Authors v-bind:data="data.podcast" v-if="data.podcast.authors.length > 0"></Authors>
    </div>

    <div class="col-12">
      <Table v-bind:data="data.podcast" v-bind:category="data.category"></Table>
    </div>

  </div>
</template>